// Styling for the contact-me form

#contact {
  width: 100%;
  position: relative;

  margin-top: $margin;

  // remove outline
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  > form {
    margin: 0 auto;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;

    @include desktop() {
      max-width: 50%;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .title {
      width: 100%;
      margin: $margin auto;
      text-align: center;
    }

    button {
      margin: $margin auto;
    }
  }
}
