@keyframes float {
  0% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(-10%);
  }
}
