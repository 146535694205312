div.linebreak-input {
  $label-size: 1rem;

  width: 95%;

  position: relative;
  margin: ($label-size / 2) auto;

  @include desktop() {
    max-width: 45%;

    &.full-width {
      max-width: 95% !important;
    }
  }

  label {
    position: absolute;
    left: 10%;
    transform: translateY(-($label-size / 2));
    font-size: $label-size;
    color: color(text-);
    background-color: color(base);

    span {
      margin: auto 0.2rem;
    }
  }

  input,
  textarea {
    background-color: transparent;
    border: 0.05rem solid color(primary-dark);
    border-radius: 0.3rem;
    font-size: $label-size * 1.2;
    color: color(text-);
    padding: 0.5rem;
    width: 100%;
  }

  input:focus {
    outline: none;
    border: 0.05rem solid color(accent);
  }
}
